<template>
  <div class="edu-frame">
    <edu-nav-bar title="填写心得" :show="show" @showPopup="show=true"
                 @hidePopup="show=false"></edu-nav-bar>

    <div :style="activitybuy.isSubmitComment == 1 ? 'height: calc(100vh - 46px - 20px)' : ''" style="width: 96%;margin: 10px auto;background-color: white;height: calc(100vh - 46px - 20px - 44px);overflow: auto;">

      <div class="class-item" style="flex-direction: row;display: flex;border: 1px solid lightgray;margin: 10px auto;padding: 10px;">
        <div class="class-detail">
          <div class="class-detail-title">活动名称：{{ activitybuy.activityName }}</div>
          <div class="class-detail-more">报名时间：{{ activitybuy.buyTime }}</div>
          <div class="class-detail-more">
            <div class="class-detail-row">
              <div class="class-detail-row-title">志愿者名称：{{ activitybuy.fullName }}</div>
              <div class="class-detail-row-title">联系电话：{{ activitybuy.mobileTelNo }}</div>
            </div>
            <div class="class-detail-row">
              <div class="class-detail-row-title">活动开始时间：{{ activitybuy.actBeginTime }}</div>
            </div>
            <div class="class-detail-row">
              <div class="class-detail-row-title">活动结束时间：{{ activitybuy.actEndTime }}</div>
            </div>
          </div>

<!--          <div style="background-color: #f0f0f0;padding: 5px;">
            <div style="font-weight: bold;">填写资料：</div>
            <div class="class-detail-more" v-for="(detail, dindex) in activitybuy.settingdetails" :key="'d_'+dindex">
              <div class="class-detail-row-title">{{detail.itemName}}：{{ detail.answer }}</div>
            </div>
          </div>-->

        </div>

      </div>

<!--      <div style="font-size: 15px;margin-top: 10px;margin-left: 13px;margin-bottom: 10px;color: gray;font-weight: bold;">心得与体会：</div>-->

      <div class="class-comment">


          <div style="font-size: 14px;color: gray;">活动流程/环节有哪些？</div>
          <van-field
              :readonly="activitybuy.isSubmitComment==1"
              v-model="activitybuy.commentFlow"
              label-width="0px"
              placeholder="活动流程/环节有哪些？"
              label-align="top"
              rows="3"
              autosize
              type="textarea"
              maxlength="100"
              show-word-limit
          />

        <div style="margin-top: 10px;font-size: 14px;color: gray;">本活动在哪里开展？</div>
        <van-field :readonly="activitybuy.isSubmitComment==1" v-model="activitybuy.commentAddress" label-width="0px" placeholder="本活动在哪里开展？" label-align="top" maxlength="100"/>

        <div style="display: flex;align-items: center;height: 50px;">
          <div style="font-size: 14px;color: gray;flex: 1;display: flex;align-items: center;">本活动共有多少个家长参加？</div>
          <van-stepper :disabled="activitybuy.isSubmitComment==1" v-model="activitybuy.commentParentCount" :min="0"/>
        </div>
        <div style="background-color: rgb(245 245 245);border: 0px;height: 1px;width: 93%;margin: 0 auto;padding: 0;"/>

        <div style="display: flex;align-items: center;height: 50px;">
          <div style="font-size: 14px;color: gray;flex: 1;display: flex;align-items: center;">本活动共有多少个孩子参加？</div>
          <van-stepper :disabled="activitybuy.isSubmitComment==1" v-model="activitybuy.commentChildCount" :min="0"/>
        </div>
        <div style="background-color: rgb(245 245 245);border: 0px;height: 1px;width: 93%;margin: 0 auto;padding: 0;"/>


        <div style="display: flex;align-items: center;height: 100px;">
          <div style="font-size: 14px;color: gray;flex: 1;height: 50px;display: flex;align-items: center;">本活动孩子年龄范围？</div>
          <div style="display: flex;flex-direction: column;align-items: center;">
            <van-stepper :disabled="activitybuy.isSubmitComment==1" v-model="activitybuy.commentMinAge" :min="0"/>
            <div style="margin: 5px 0px;font-size: 14px;">至</div>
            <van-stepper :disabled="activitybuy.isSubmitComment==1" v-model="activitybuy.commentMaxAge" :min="0"/>
          </div>
        </div>
        <div style="background-color: rgb(245 245 245);border: 0px;height: 1px;width: 93%;margin: 0 auto;padding: 0;"/>


        <div style="margin-top: 10px;font-size: 14px;color: gray;">写下孩子们的活动反馈或者你的感想吧！</div>
          <van-field
              :readonly="activitybuy.isSubmitComment==1"
              v-model="activitybuy.comment"
              label-width="0px"
              placeholder="写下孩子们的活动反馈或者你的感想吧！"
              label-align="top"
              rows="3"
              autosize
              type="textarea"
              maxlength="100"
              show-word-limit
          />

        <div style="display: flex;align-items: center;margin-top: 10px;">
          <div style="font-size: 14px;color: gray;flex: 1;display: flex;align-items: center;">活动精彩照片</div>
        </div>
        <van-uploader :disabled="activitybuy.isSubmitComment==1" v-model="fileList" multiple :max-count="9" style="margin-top: 10px;" :after-read="changeFile" @delete="changeFile"/>
      </div>

    </div>

    <van-button v-if="activitybuy.isSubmitComment != 1" :loading="submitting" square block type="primary" @click="submitComment">保存</van-button>
  </div>
</template>
<script>
import EduNavBar from "@/components/VolunteerEduNavBar.vue";
import {Button, Field, Uploader, Stepper} from "vant";
import Tools from "@/api/Tools";
import ActivityApi from "@/api/ActivityApi";
export default {
  components: {
    EduNavBar,
    VanButton: Button,
    VanField: Field,
    VanUploader: Uploader,
    VanStepper: Stepper
    /*VanCheckbox: Checkbox*/
  },
  data() {
    return {
      show: false,
      loading: false,
      finished: false,
      pageInfo: { page: 1, pageSize: 15, condition: {} },
      activitybuy: {},
      cust: Tools.getCurCust(),
      comment: {},
      fileList: [],
      files: [],
      deleteFileIDs: [],
      submitting:false
    }
  },
  methods: {
    submitComment() {
      if (this.submitting) {
        return
      }

      this.$dialog.confirm({title: '注意', message: '心得提交后无法修改，请确认后再提交。'}).then(() => {

        for (var i = 0; i < 9; i++) {
          this.comment['pic'+(i+1)] = ''
          if (i < this.files.length) {
            this.comment['pic'+(i+1)] = this.files[i].base64img
          }
        }
        this.comment.comment = this.activitybuy.comment
        this.comment.activityBuyMasterId = this.activitybuy.activityBuyMasterId
        this.comment.commentFlow = this.activitybuy.commentFlow
        this.comment.commentAddress = this.activitybuy.commentAddress
        this.comment.commentParentCount = this.activitybuy.commentParentCount
        this.comment.commentChildCount = this.activitybuy.commentChildCount
        this.comment.commentMinAge = this.activitybuy.commentMinAge
        this.comment.commentMaxAge = this.activitybuy.commentMaxAge
        console.log('comments： ==', this.comment)
        this.submitting = true
        ActivityApi.submitComment(this.comment).then(response => {
          this.submitting = false
          this.$dialog.alert({message: response.msg})
          if (response.code == 100) {
            this.$router.back()
          }
        }).catch(() => { this.submitting = false })

      }).catch(() => { this.submitting = false })

    },
    changeFile(e) {
      console.log('e.url == ', e)
      if (e.url != null) {
        var fileID = null;
        var fileIndex = -1
        for (var i = 0; i < this.files.length; i++) {
          if (this.files[i].base64img == e.url) {
            fileID = this.files[i].fileID
            fileIndex = i
            break
          }
        }
        console.log('fileIndex ' + fileIndex)
        if (fileIndex != null) {
          this.files.splice(fileIndex, 1)
          this.deleteFileIDs.push(fileID)
        }
      } else {
        var self = this
        self.files = []
        // eslint-disable-next-line no-redeclare
        for (var i = 0; i < this.fileList.length; i++) {
          if (this.fileList[i].file != null) {
            // this.files.push({ base64img: this.fileList[i].content, fileName: this.fileList[i].file.name })
            Tools.compressFile(this.fileList[i].file).then(response=> {
              console.log('file response', response)
              Tools.uploadFileByBase64(response, (base64Response)=>{
                // console.log(base64Response)
                self.files.push({ base64img: base64Response, fileName: response.name })
              })
            })
          }
        }

      }
      // this.changeFileList()
    },
    getActivityBuysRptById() {
      var activityBuyMasterId = this.$route.query.activityBuyMasterId
      ActivityApi.getActivityBuysRptById({ activityBuyMasterId: activityBuyMasterId }).then(response => {
        this.activitybuy = response.res
        console.log('response == ', response)
        for (var i = 1; i < 9; i++) {
          if (this.activitybuy['pic' + i] != null && this.activitybuy['pic' + i] != '') {
            this.files.push({ base64img: window.location.href.substring(0, window.location.href.indexOf('#/')) + this.activitybuy['pic' + i] })
            this.fileList.push({ url: window.location.href.substring(0, window.location.href.indexOf('#/')) + this.activitybuy['pic' + i] })
          }
        }
        console.log('this.fileList == ', this.fileList)
      })
    }
  },
  mounted() {
    this.getActivityBuysRptById()
  }
}
</script>
<style scoped>
.class-comment {
  width: 95%;
  margin: 0 auto;
}
</style>

